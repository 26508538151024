import { Icon } from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import React from 'react'

import styles from './styles'

const useStyles = makeStyles(styles)

const Content = ({
  content,
  index,
  setSelectedIndex,
  style,
}) => {
  const classes = useStyles()
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <div className={classes.arrow}>
        <Icon
          name="arrow_back_ios_new"
          color="black"
          size={14}
          disableHover
          onClick={() => setSelectedIndex({ value: index - 1, direction: 'left' })}
        />
      </div>
      <div className={classes.sliderContent} style={style}>
        {content}
      </div>
      <div className={classes.arrow}>
        <Icon
          name="arrow_forward_ios_new"
          color="black"
          size={14}
          disableHover
          onClick={() => setSelectedIndex({ value: index + 1, direction: 'right' })}
        />
      </div>
    </div>
  )
}

export default Content
