export default (theme) => {
  return {
    arrow: {
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        top: 30,
      },
    },

    sliderContent: {
      padding: '0 1rem',
      minHeight: '100px',
      flex: 1,
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },

    marginBottomTwo: {
      marginBottom: '2rem',
    },
  }
}
