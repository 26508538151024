import { makeStyles } from '@material-ui/core/styles'
import MuiTab from '@material-ui/core/Tab'
import MuiTabs from '@material-ui/core/Tabs'
import React from 'react'

import Underline from '../Underline'
import styles from './styles'

const useStyles = makeStyles(styles)

/**
 * @typedef {import('@material-ui/core/Tabs').TabsProps} MuiTabsProps
 *
 * @typedef {import('@material-ui/core/Tab').TabProps} MuiTabProps
 *
 * @typedef {MuiTabsProps & {
 *   activeTab: number;
 *   tabs: (Pick<MuiTabProps, 'label' | 'disableRipple'> & {
 *     props?: MuiTabProps;
 *     content?: React.ReactNode;
 *   })[];
 *   noBottomBorder?: boolean;
 *   renderTab?: (content?: React.ReactNode) => React.ReactNode;
 * }} TabsProps
 */

/**
 * ```
 * import { Tabs } from '@pickupp/ui/core'
 * ```
 *
 * https://material-ui.com/api/tab/ <br>
 * https://material-ui.com/api/tabs/
 */

/**
 * @param {TabsProps} props
 */
const Tabs = ({
  activeTab,
  tabs,
  noBottomBorder = false,
  disableRipple = false,
  centered,
  renderTab,
  onChange,
  color,
  className,
  ...muiProps
}) => {
  const indicatorHeight = '3px'

  const classes = useStyles({
    noBottomBorder,
    indicatorHeight,
  })

  return (
    <>
      <MuiTabs
        centered={centered}
        value={activeTab}
        className={className}
        classes={{
          scroller: classes.scroller,
          indicator: classes.indicator,
        }}
        onChange={(event, value) => {
          onChange(value)
        }}
        TabIndicatorProps={{
          children: (
            <Underline
              width="2rem"
              height={indicatorHeight}
              align={null}
              color={color}
            />
          ),
        }}
        {...muiProps}
      >
        {tabs.map((tab) => (
          <MuiTab
            key={tab.label}
            label={tab.label}
            disableRipple={disableRipple}
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
            {...tab.props}
          />
        ))}
      </MuiTabs>
      {renderTab ?
        renderTab(tabs[activeTab].content) :
        tabs[activeTab].content}
    </>
  )
}

export default Tabs
