export default (theme) => {
  const { appBarHeight } = theme.global
  return {
    mainContainer: {
      position: 'relative',
      zIndex: 5,
    },

    textContainer: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
      },
    },

    rightColumnContainer: {
      maxWidth: '60%',
      marginLeft: '2rem',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        padding: 0,
        maxWidth: '100%',
        justifyContent: 'center',
      },
    },

    rightColumnText: {
      justifyContent: 'flex-start',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      alignItems: 'center',
    },

    linkButton: {
      alignSelf: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(1),
        alignSelf: 'center',
        marginLeft: 0,
      },
    },

    searchWrapper: {
      maxWidth: 380,
      margin: theme.spacing(2, 0),
      background: theme.palette.common.white,
      padding: theme.spacing(1, 2),
      borderRadius: 5,
      boxShadow: '0px 3px 6px 0 rgba(0, 0, 0, 0.16)',
      '& .MuiInput-root': {
        margin: 0,
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(2),
        maxWidth: 350,
      },
      [theme.breakpoints.down('xs')]: {
        margin: '0 auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        maxWidth: '85%',
      },
    },

    stickySection: ({ scrollDown, isMobile }) => {
      const responsiveAppBarHeight = isMobile ? appBarHeight.mobile : appBarHeight.desktop
      const top = scrollDown ? 0 : responsiveAppBarHeight

      return {
        position: 'fixed',
        top,
        left: 0,
        background: 'white',
        width: '100%',
        zIndex: 5,
        margin: 0,
        transition: 'top 0.25s',
        padding: theme.spacing(0, 6),
        [theme.breakpoints.down('sm')]: {
          padding: 0,
        },
      }
    },

    searchInput: {
      flexGrow: 1,
      '& input': {
        padding: 0,
      },
    },
    searchButton: {
      padding: 0,
    },

    leftColumnContainer: {
      maxWidth: '35%',
      gridColumnGap: '3.2rem',
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },

    headerContent: {
      transition: 'transform .35s ease-in-out',
      padding: theme.spacing(2, 2),
      position: 'relative',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding: 0,
        backgroundColor: '#e0e0e0',
      },
    },

    dateText: {
      color: theme.palette.common.lightGrey,
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
      },
    },

    image: {
      width: '100%',
      height: 'auto',
    },

    body: {
      maxWidth: theme.global.maxContentWidth,
      padding: theme.spacing(0, 4),
      margin: '4rem auto',
      marginTop: theme.spacing(3),
      textAlign: 'center',
      minHeight: 120,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        margin: theme.spacing(3, 0, 0, 0),
      },
    },

    postText: {
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },

    postTagsContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(0.5),
    },

    breadcrumbContainer: {
      padding: theme.spacing(2, 0),
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(3),
      },
    },

    breadcrumbHead: {
      cursor: 'pointer',
      color: theme.palette.common.primary,
    },

    breadcrumbDivider: {
      color: theme.palette.common.darkGrey,
      margin: theme.spacing(0, 1),
      cursor: 'pointer',
      borderRadius: 25,
      '&:hover': {
        backgroundColor: theme.palette.common.paleGrey,
      },
    },

    tabsContainer: {
      marginBottom: theme.spacing(2),
      '& .MuiTab-root': {
        textTransform: 'none',
      },
    },

    paginationContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },

    postsShowingCount: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },

    pagination: {
      margin: 0,
      marginBlockStart: 0,
      marginBlockEnd: 0,
      paddingInlineStart: 0,
    },
  }
}
