import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'

import Icon from '../Icon'
import styles from './styles'

const useStyles = makeStyles(styles)

/**
 * @typedef {{
 *   total: number;
 *   perPage: number;
 *   handlePageClick?: (selected: boolean, offset: number, perPage: number) => void;
 *   forcePage?: number;
 *   containerClassName?: string;
 *   marginPagesDisplayed?: number;
 *   pageRangeDisplayed?: number;
 * }} PaginationProps
 */

/**
 * ```
 * import { Pagination } from '@pickupp/ui/core'
 * ```
 *
 * https://github.com/AdeleD/react-paginate
 */

/**
 * @param {PaginationProps} props
 */
const Pagination = (props) => {
  const classes = useStyles()
  const [pageCount, setPageCount] = useState(Math.ceil(props.total / props.perPage))

  useEffect(
    () => {
      setPageCount(Math.ceil(props.total / props.perPage))
    },
    [props.total, props.perPage],
  )

  const handlePageClick = (data) => {
    const newOffset = Math.ceil(data.selected * props.perPage)
    if (props.handlePageClick) {
      props.handlePageClick(data.selected, newOffset, props.perPage)
    }
  }

  return (
    <ReactPaginate
      previousLabel={(
        <Icon
          name="chevron_left"
          className={classes.paginationIcon}
        />
      )}
      nextLabel={(
        <Icon
          name="chevron_right"
          className={classes.paginationIcon}
        />
      )}
      breakLabel={<span>...</span>}
      breakClassName="break-me"
      pageCount={pageCount}
      marginPagesDisplayed={props.marginPagesDisplayed || 2}
      pageRangeDisplayed={props.pageRangeDisplayed || 5}
      onPageChange={handlePageClick}
      containerClassName={cx(classes.pagination, props.containerClassName)}
      activeClassName="active"
      forcePage={props.forcePage}
    />
  )
}

export default Pagination
