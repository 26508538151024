export default (theme) => ({
  pagination: {
    display: 'flex',

    '& li': {
      display: 'inline-block',
      height: '2rem',
      margin: theme.spacing(0, 0.5),
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(0, 0.35),
      },
    },

    '& a': {
      background: 'transparent',
      border: 'none',
      borderRadius: '2px',
      color: theme.palette.common.textPrimary,
      position: 'relative',
      minWidth: '2rem',
      height: '2rem',
      padding: '0 0.25rem',
      margin: 0,
      display: 'inline-block',
      fontWeight: 500,
      textTransform: 'uppercase',
      lineHeight: '2rem',
      letterSpacing: 0,
      overflow: 'hidden',
      outline: 'none',
      cursor: 'pointer',
      textDecoration: 'none',
      textAlign: 'center',
      '& span': {
        display: 'block',
        lineHeight: '2rem',
        height: '2rem',
      },
      '&::-moz-focus-inner': {
        border: 0,
      },
      '&:hover': {
        '&::not(.disabled)': {
          backgroundColor: 'rgba(158,158,158, 0.20)',
        },
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: '1.7rem',
      },
    },

    '& .active': {
      borderRadius: theme.spacing(1),
      backgroundColor: 'rgba(158,158,158, 0.40)',
    },

    '& .disabled': {
      '& a': {
        cursor: 'not-allowed',
      },
    },
  },

  paginationIcon: {
    lineHeight: '22px',
  },
})
