import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import {
  Cell,
  Chip,
  Grid,
  Icon,
  IconButton,
  Input,
  Pagination,
  SearchIcon,
  Tabs,
  Typography,
} from '@pickupp/ui/core'
import { makeStyles, useTheme } from '@pickupp/ui/styles'
import cx from 'classnames'
import moment from 'moment'
import qs from 'qs'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { SwitchTransition, Transition } from 'react-transition-group'

import { BROWSER } from '~/config'
import Content from '~/src/components/Content'
import Image from '~/src/components/Image'
import LinkButton from '~/src/components/LinkButton'
import Page from '~/src/components/Page'
import { LocaleContext, PostContext } from '~/src/contexts'
import { useIsMobile, useSettings, useTranslation } from '~/src/hooks'
import { convertRemToPixel, makeTransitions } from '~/src/services/utils'

import PostCard from '../PostCard'
import styles from './styles'

const useStyles = makeStyles(styles)

const AnimatedImage = ({
  style,
  shouldAnimate,
  item,
}) => {
  const classes = useStyles()

  return (
    <SwitchTransition mode="out-in">
      <Transition
        key={item.title}
        in={shouldAnimate}
        timeout={200}
      >
        {(state) => {
          return (
            <Image
              loading="lazy"
              src={item.cover_image?.src}
              alt={item.cover_image?.alt}
              className={classes.image}
              style={{
                ...style.defaultStyle,
                ...style[state],
              }}
            />
          )
        }}
      </Transition>
    </SwitchTransition>
  )
}

const AnimatedContent = ({
  shouldAnimate,
  selectedIndex,
  onChangeIndex,
  item,
  style,
}) => {
  const t = useTranslation()
  const classes = useStyles()
  return (
    <SwitchTransition mode="out-in">
      <Transition
        key={item.title}
        in={shouldAnimate}
        timeout={200}
      >
        {(state) => (
          <>
            <Content
              content={(
                <Grid className={classes.textContainer}>
                  <Typography className={cx(classes.postText, classes.dateText)}>
                    {moment.utc(item.createdAt).format('lll')}
                  </Typography>
                  <Typography
                    weight={700}
                    style={{ margin: '1rem 0' }}
                    className={classes.postText}
                  >
                    {item.title}
                  </Typography>
                  <Typography className={classes.postText}>
                    {item.ogDescription}
                  </Typography>
                  {item.tags && (
                    <div className={classes.postTagsContainer}>
                      {item.tags.map((tag) => (
                        <Chip
                          key={tag}
                          label={tag}
                          className={classes.tag}
                        />
                      ))}
                    </div>
                  )}
                  <LinkButton to={`/blog/${item.meta.slug}`} className={classes.linkButton}>
                    {t('learnMore')}
                  </LinkButton>
                </Grid>
              )}
              index={selectedIndex}
              setSelectedIndex={onChangeIndex}
              style={{
                ...style.defaultStyle,
                ...style[state],
              }}
            />
          </>
        )}
      </Transition>
    </SwitchTransition>
  )
}

const Header = () => {
  const isMobile = useIsMobile()
  const { posts: allPosts } = useContext(PostContext)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [item, setItem] = useState(allPosts[0] || {})
  const [shouldAnimate, setShouldAnimate] = useState(false)
  const [slideAnimationStyles, setSlideAnimationStyles] = useState({})
  const imageAnimationStyles = makeTransitions({
    duration: isMobile ? 200 : 150,
    type: 'fadeIn',
  })

  const SLIDE_DIRECTION_MAP = {
    left: 'slideLeftFadeOut',
    right: 'slideRightFadeOut',
  }
  const animationDelay = isMobile ? 300 : 200

  const { blog_cover_image } = useSettings()
  const classes = useStyles({ blog_cover_image })

  useEffect(() => {
    setItem(allPosts[selectedIndex])
  }, [selectedIndex])

  useEffect(() => {
    if (shouldAnimate) {
      setTimeout(() => {
        setShouldAnimate(false)
      }, animationDelay)
    }
  }, [shouldAnimate])

  const onChangeIndex = ({ value, direction }) => {
    if (value > allPosts.length - 1) {
      setSelectedIndex(0)
    } else if (value < 0) {
      setSelectedIndex(allPosts.length - 1)
    } else {
      setSelectedIndex(value)
    }
    setShouldAnimate(true)
    const slidePropertyName = SLIDE_DIRECTION_MAP[direction]
    setSlideAnimationStyles(makeTransitions({
      duration: isMobile ? 600 : 500,
      type: slidePropertyName,
    }))
  }

  return (
    <div className={classes.body}>
      <Grid className={classes.headerContent}>
        <Cell className={classes.leftColumnContainer}>
          <AnimatedImage
            shouldAnimate={shouldAnimate}
            style={imageAnimationStyles}
            item={item}
          />
        </Cell>
        <Grid className={classes.rightColumnContainer}>
          <AnimatedContent
            shouldAnimate={shouldAnimate}
            selectedIndex={selectedIndex}
            onChangeIndex={onChangeIndex}
            style={slideAnimationStyles}
            item={item}
          />
        </Grid>
      </Grid>
    </div>
  )
}

const Body = ({ searchRef, searchQuery, setSearchQuery }) => {
  const { blog_cover_image } = useSettings()
  const isMobile = useIsMobile()
  const theme = useTheme()
  const t = useTranslation()
  const { language } = useContext(LocaleContext)
  const { posts: allPosts, tags } = useContext(PostContext)

  const [activeTopicTab, setActiveTopicTab] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [offset, setOffset] = useState(0)
  const perPage = 9
  const [posts, setPosts] = useState(allPosts)
  const [from, setFrom] = useState(1)
  const [to, setTo] = useState(1)

  const bodyRef = useRef(null)

  // New tags array including the special tag to show all posts
  const allTag = t('blog.tag.all')
  const allTopicTags = [allTag, ...tags]

  // Set initial selected tags based on params
  const params = BROWSER ? qs.parse(window.location.search, { ignoreQueryPrefix: true }) : null
  const [selectedTags, setSelectedTags] = useState(allTag)

  // Make search bar stick to appBar
  const appBarHeight = useRef(0)
  const [stickySectionActive, setStickySectionActive] = useState(false)
  const stickySectionRef = useRef()
  const stickySectionSnapRef = useRef()
  const [scrollDown, setScrolldown] = useState(false)

  const classes = useStyles({ blog_cover_image, scrollDown, isMobile })

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < prevPos.y) {
      setScrolldown(true)
    } else {
      setScrolldown(false)
    }
  }, [scrollDown])

  const handleScroll = () => {
    if (!stickySectionRef.current || !stickySectionSnapRef.current) return

    const { top } = stickySectionSnapRef.current.getBoundingClientRect()
    setStickySectionActive(top <= 0)
  }

  useEffect(() => {
    if (params && params.tag && tags.includes(params.tag)) {
      setSelectedTags([params.tag])
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const { mobile, desktop } = theme.global.appBarHeight
    if (isMobile) {
      appBarHeight.current = convertRemToPixel(mobile)
    } else {
      appBarHeight.current = convertRemToPixel(desktop)
    }
  }, [isMobile])

  useEffect(() => {
    const newFrom = offset + 1
    const newTo = offset + perPage
    setFrom(newFrom)
    setTo(newTo > posts.length ? posts.length : newTo)
  }, [currentPage, posts.length])

  useEffect(() => {
    // Filter posts by selected tags
    let newPosts = allPosts.filter((item) => {
      if (selectedTags.includes(allTag)) return true
      if (!item.tags) return false
      return item.tags.some((tag) => selectedTags.includes(tag))
    })

    // Filter posts by query
    if (searchQuery !== '') {
      const lowerCasedQuery = searchQuery.toLowerCase()
      newPosts = newPosts.filter((item) => {
        if (!item.tags) return false
        return item.tags.some((tag) => tag.toLowerCase().includes(lowerCasedQuery)) ||
        item.title.toLowerCase().includes(lowerCasedQuery)
      })
    }

    setPosts(newPosts)
    // Reset pagination
    setCurrentPage(0)
    setOffset(0)
  }, [selectedTags, searchQuery])

  const scrollToBodyTop = () => {
    if (BROWSER && bodyRef.current) {
      // smooth scroll needs a small period to update offsetTop
      setTimeout(() => {
        window.scrollTo({
          top: bodyRef.current.offsetTop,
          behavior: 'smooth',
        })
      }, 1)
    }
  }

  const backToBlogPage = () => {
    setSearchQuery('')
    searchRef.current.value = ''
    if (stickySectionActive) {
      scrollToBodyTop()
    }
  }

  const handleTabChange = (tabIndex) => {
    setActiveTopicTab(tabIndex)
    setSelectedTags(allTopicTags[tabIndex] === selectedTags ? allTag : allTopicTags[tabIndex])
    if (stickySectionActive) {
      scrollToBodyTop()
    }
  }

  const handlePageClick = (pageValue, offsetValue) => {
    setCurrentPage(pageValue)
    setOffset(offsetValue)
    scrollToBodyTop()
  }

  const onSearchKeyUp = (event) => {
    // Handle enter key
    if (event.keyCode === 13) {
      setSearchQuery(event.target.value)
      if (stickySectionActive) {
        scrollToBodyTop()
      }
    }
  }

  const submitSearchQuery = () => {
    setSearchQuery(searchRef.current.value)
    if (stickySectionActive) {
      scrollToBodyTop()
    }
  }

  const searchResultMessage = language.toLowerCase() !== 'zh' ?
    `${posts.length} ${t('blog.search.result')} "${searchQuery}"` :
    `"${searchQuery}" 的 ${posts.length} ${t('blog.search.result')}`

  const shouldShowStickySection = stickySectionActive && !scrollDown

  return (
    <div className={classes.body} ref={bodyRef}>
      <div
        ref={stickySectionSnapRef}
        style={{
          height: shouldShowStickySection ? stickySectionRef.current?.clientHeight : 0,
        }}
      />
      <div
        className={cx({
          [classes.stickySection]: shouldShowStickySection,
        })}
        ref={stickySectionRef}
      >
        <Grid
          alignItems="center"
          className={classes.searchWrapper}
        >
          <Input
            inputRef={searchRef}
            disableUnderline
            placeholder={t('blog.search.placeholder')}
            className={classes.searchInput}
            onKeyUp={onSearchKeyUp}
          />
          <IconButton
            className={classes.searchButton}
            onClick={submitSearchQuery}
          >
            <SearchIcon />
          </IconButton>
        </Grid>
        <Grid spacing={1}>
          <Cell xs={12}>
            {searchQuery ? (
              <Grid
                direction="row"
                justify="flex-start"
                alignItems="center"
                className={classes.breadcrumbContainer}
              >
                <Typography
                  className={classes.breadcrumbHead}
                  onClick={backToBlogPage}
                >
                  {t('blog.search.breadcrumbHead')}
                </Typography>
                <Icon
                  name="chevron_right"
                  className={classes.breadcrumbDivider}
                />
                <Typography>
                  {searchResultMessage}
                </Typography>
              </Grid>
            ) : (
              <div className={classes.tabsContainer}>
                <Tabs
                  tabs={allTopicTags.map((tag) => ({ label: tag }))}
                  activeTab={activeTopicTab}
                  variant="scrollable"
                  scrollButtons="on"
                  onChange={handleTabChange}
                />
              </div>
            )}
          </Cell>
        </Grid>
      </div>
      <Grid spacing={5}>
        {posts.slice(offset, offset + perPage).map((item, index) => (
          <PostCard key={index} post={item} />
        ))}
      </Grid>
      {posts.length > 0 && (
        <div className={classes.paginationContainer}>
          <Typography className={classes.postsShowingCount}>
            {t('showing')} {from} - {to} ({t('total')} {posts.length})
          </Typography>
          <Pagination
            total={posts.length}
            perPage={perPage}
            forcePage={currentPage}
            handlePageClick={handlePageClick}
            containerClassName={classes.pagination}
            pageRangeDisplayed={isMobile ? 3 : 5}
          />
        </div>
      )}
    </div>
  )
}

const PostList = () => {
  const { blog_cover_image } = useSettings()
  const classes = useStyles({ blog_cover_image })
  const [searchQuery, setSearchQuery] = useState('')
  const searchRef = useRef()

  return (
    <Page className={classes.mainContainer}>
      <Header />
      <Body searchRef={searchRef} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
    </Page>
  )
}

export default PostList
