export default () => {
  return {
    scroller: ({
      noBottomBorder,
    }) => {
      if (noBottomBorder) {
        return {
          border: 0,
        }
      }
      return null
    },

    indicator: ({
      indicatorHeight,
    }) => {
      return {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        height: indicatorHeight,
      }
    },

    tabRoot: {
      fontWeight: 400,
    },

    tabSelected: {
      fontWeight: 800,
    },
  }
}
